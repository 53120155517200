<div class="placeholder">
  @for (notification of notifications$ | async; track notification) {
    <mat-card>
      <mat-card-actions align="end">
        <button mat-icon-button color="warn" matTooltip="Close" (click)="onClose(notification.id)">
          <mat-icon>close</mat-icon>
        </button>
      </mat-card-actions>
      <mat-card-title>
        {{ notification.title }}
      </mat-card-title>
      <mat-card-subtitle>
        {{ notification.createDate | date:'yyyy-MM-dd HH:mm' }}
      </mat-card-subtitle>
      <mat-card-content>
        {{ notification.message }}
      </mat-card-content>
      <mat-card-actions>
        @if (notification.actionLink) {
          <button mat-icon-button (click)="openLink(notification.actionLink)"
            color="accent">
            <mat-icon>open_in_new</mat-icon>
          </button>
        }
      </mat-card-actions>
      <mat-card-footer>
      </mat-card-footer>
    </mat-card>
  }
</div>