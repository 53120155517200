import { Component } from '@angular/core';
import { Observable } from 'rxjs';
import { first } from 'rxjs/operators';
import { Router } from '@angular/router';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { Notification } from '../../../models/notification.model';
import { NotificationService } from '../../../services/notification.service';

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    MatCardModule,
    MatIconModule,
    MatButtonModule,
  ],
  providers: [
  ]
})
export class NotificationComponent {
  public notifications$!: Observable<Notification[]>;
  constructor(
    private readonly notificationService: NotificationService,
    private readonly router: Router,
  ) {
    this.notifications$ = this.notificationService.getAll();
  }

  onClose(notificationId: number): void {
    this.notificationService.close(notificationId).pipe(
      first()
    ).subscribe(
      () => this.notifications$ = this.notificationService.getAll()
    );
  }

  openLink(actionLink: string): void {
    this.router.navigate([`${actionLink}`]);
  }

}
